import { Link } from "gatsby"
import React from "react"
import styles from "../footer/footer.module.css"


const Footer = (props) => (
  <footer className={styles.footer}>
    <div className="container">
      <div className={styles.links + " row"}>
        <ul className="col-sm-4">
          <div className={styles.footerHeading}>About</div>
          <li className={styles.link}><Link to="/about-us/">About Us</Link></li>
          <li className={styles.link}><Link to="/contributors/">Contributors</Link></li>
          <li className={styles.link}><Link to="/write-for-us">Write For Us</Link></li>
        </ul>
        <ul className="col-sm-4">
          <div className={styles.footerHeading}>Legal</div>
          <li className={styles.link}><Link to="/privacy-policy/">Privacy Policy</Link></li>
          <li className={styles.link}><Link to="/affiliate-disclosure/">Affiliate Disclosure</Link></li>
        </ul>
        <ul className="col-sm-4">
          <div className={styles.footerHeading}>Social</div>
          <li className={styles.link}><a href="https://twitter.com/spot_code">Twitter</a></li>
          <li className={styles.link}><a href="https://facebook.com/codespot">Facebook</a></li>
        </ul>
      </div>
      <div className={styles.copy}>
        <span>{props.siteTitle}</span> © {new Date().getFullYear() + " All Rights Reserved"}
      </div>
    </div>
</footer>
)

export default Footer