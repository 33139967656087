import React from "react"
import {Navbar, Nav} from 'react-bootstrap'
import styles from '../header/header.module.css'
import logoColored from '../../images/logo-1-color.png'
import twitterLogo from '../../images/twitter.png'
import facebookLogo from '../../images/facebook.png'

const Header = (props) => (

  <Navbar className={styles.navbar} variant="dark">
    <div className='container'>
      <Navbar.Brand className='ml-3' href="/"><img className={styles.logo} alt="CodeSpot Logo" src={logoColored}></img></Navbar.Brand>
      <Nav className="ml-auto mr-2 text-uppercase">
        <Nav.Link className={styles.linkText} href="/courses/">Courses</Nav.Link>
        <Nav.Link className={styles.linkText} href="https://www.codespot.org/best-books-for-programmers/">Books</Nav.Link>
        <Nav.Link className={styles.link} href="https://twitter.com/spot_code"><img className={styles.logoSocial} alt="Twitter Logo" src={twitterLogo}></img></Nav.Link>
        <Nav.Link className={styles.link} href="https://facebook.com/codespot"><img className={styles.logoSocial} alt="Facebook Logo" src={facebookLogo}></img></Nav.Link>
      </Nav>
    </div>
  </Navbar>
)

export default Header
